import { useEffect, useState, useRef, forwardRef } from "react";
import { useParams } from 'react-router-dom';
import { SubnetForm } from "../components/forms/subnet_form";
import { Container, Stack } from "react-bootstrap";
import { Subnet } from "../components/subnet";
import { Bitmap } from "../components/bitmap";
import { SubSizeTable } from "../components/sub_size_table";
import { useHeaderTags } from "../components/custom_hook";
import { SvgCanvas } from "../components/svg_canvas";
import { useWindowResize } from "../components/useWindowResize";
import { YouTube } from "../components/youtube";


export const SubnetFlow = (props) => {

  const [subnet, setSubnet] = useState({});
  const [myIp, setMyIp] = useState();
  const [showDesc, setShowDesc] = useState(!props.sid);
  const [classful, setClassful] = useState(false);
  const treeRefs = useRef(new Set());
  const branchRefs = useRef(new Set());
  const canvasRef = useRef();
  const centerRef = useRef();
  let initSub = ""
  const uriParams = useParams();

  if (uriParams.sid) {
    initSub = uriParams.sid.replace("_", "/").split(" ")[0];
  }
  const [target, setTarget] = useState(initSub);
  const [forceRender, setForceRender ] = useState(false);

  useHeaderTags(props);
  useWindowResize(canvasRef);

  const doRefresh = () => {
    let prevVal = forceRender;
    setForceRender(~prevVal);
  }

  useEffect(() => {
    const fetchIp = async () => {
      try {
        let ipIsAt = process.env.REACT_APP_HOST + "/api/v1/myip";
        const inbuff = await fetch(ipIsAt);
        const d = await inbuff.json();
        if (d.myip.length > 0) {
          setMyIp(d.myip);
        }
      } catch (error) {
        console.log("No ip returned");
      }
    };
    fetchIp();
  }, [myIp]);


  const updateCenter = (e) => {
    let net = e.target.parentNode.dataset.net;
    treeRefs.current.clear();
    branchRefs.current.clear();
    subnet.element.bbNetNum = -1;
    if (net) {
      setTarget(net);
    } else {
      setTarget(e.target.textContent);
    }
  };

  const Spouse = forwardRef((props, ref) => {
    return (
      <div
        className={!props.net.spouse ? "sibling" : "spouse"}
        onClick={updateCenter}
        role="button"
        tabIndex="0"
        ref={ref}
        data-spot={props.i} 
        data-root={props.net.root}
      >
        {props.net.label}
      </div>
    );
  });

  const addTreeRef = (r) => {
    if (r === null) {
      treeRefs.current.clear();
    }
    treeRefs.current.add(r)
  }

  const addBranchRef = (r) => {
    if (r === null) {
      branchRefs.current.clear();
      return;
    }
    branchRefs.current.add(r);
  }

  const saveSub = (s) => {
    setSubnet(s);
  };

  let ipv6 = false;
  if (subnet.element) {
    ipv6 = subnet.element.ipv6;
  }

  // This is the table of subnets cells
  return (
    <>
      <Container>
        <SubnetForm
          className="subform"
          setSub={saveSub}
          center={target}
          ipv6={ipv6}
          bbVal={subnet.element&&classful?subnet.element.bbNetNum:""}
          parent={subnet.parent}
          showDesc={setShowDesc}
          classful={classful}
          allowBits = {subnet.classMask?subnet.classMask.split(":")[1] < subnet.element.maskVal:false}
          setClassful={setClassful}
        ></SubnetForm>
      </Container>
      {!showDesc && <Container>
        {subnet.err ? <div className="err">{subnet.element.label}</div> :
          <Subnet {...subnet.element} classful={classful} usage={subnet.usage} clsmap={subnet.classMask}></Subnet>}
      </Container>}
      {!showDesc && !subnet.err && (
        <Container>
          <div className="docs paper">
            <Bitmap subnet={subnet.element} classful={classful} clsmap={subnet.classMask}></Bitmap>
            <div ref={canvasRef}>
              <SvgCanvas canvasRef={canvasRef} branchRef={branchRefs} treeRef={treeRefs} forceRender={doRefresh} subnet={subnet} centerRef={centerRef}></SvgCanvas>
              <div className="center">
              <Stack gap={0} className="mx-auto">
                {subnet.tree &&
                  subnet.tree
                    .map((parent) => {
                      return (
                        <div
                          data-net={parent.label}
                          data-pos={parent.note}
                          ref={addTreeRef}
                          key={parent.label}
                          tabIndex="0"
                          className="parent"
                          role="button"
                          onClick={updateCenter}>
                          <div>
                            {parent.label}
                          </div>
                          {parent.ipv6?<div>{parent.slash64Count}</div>:<div className="footnote"><div>{parent.size + 1}</div><div>{parent.note}</div></div>
                          }
                        </div>
                      );
                    })
                    .reverse()}
              </Stack>
            </div>
              <div className="center">
                {subnet.element && (
                  <>
                    {subnet.sibLeft
                      .map((sL, id) => {
                        return <Spouse ref={addBranchRef} net={sL} key={id} i={id}></Spouse>;
                      })
                      .reverse()}
                    <div className="element centersub" ref={centerRef}>
                      <div>
                        <div className="label">Subnet:</div>
                        <span className="font-monospace text-primary">
                          {subnet.element.label}
                        </span>
                      </div>
                    </div>
                    {subnet.sibRight.map((sR, id) => {
                      return <Spouse ref={addBranchRef} net={sR} key={id} i={id + subnet.sibLeft.length + 1}></Spouse>;
                    })}
                  </>
                )}
              </div>
              {/* Childern */}
              <div style={{ 'margin': '2.5em' }}>
                {subnet.child &&
                  subnet.child.map((row, id) => {
                    return (
                      <div className="kidrow" key={id}>
                        {row.map((cell, id2) => {
                          return (
                            <div className="kids" onClick={updateCenter} key={id2} role="button" tabIndex="0">
                              {cell.label}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Container>
      )}
      {showDesc && (
        <Container>
          <div className="docs paper">
            <h3>Description</h3>
            <p>Graphical subnet calculator provides IPv4 network, integer value, host number, broadcast address, mask, wildcard, and subnet size.
              <br />For IPv6 subnets, to tool provides the IPv6 compressed and expanded format in addition to the IPv6 size. For CIDRs between /64 and /32, the equivalent IPv4 size is shown
              for reference. This assumes a /64 is the smallest allocation unit and represents a host /32 in IPv4.
            </p>
            <h3>Usage</h3>
            <p>
              Enter an IPv4 or IPv6 subnet. If the mask is not included, the tool
              will calculate the best match subnet based on the least significant bit (LSB) bit.  If the bit is clear (0), it will assume
              the addess is a subnet number. If the bit is a set (1), then the address is treated as a broadcast. Some example formats:
            </p>
            <pre><code>
              {myIp && (
                <li>
                  <span>{myIp} </span>&nbsp;<span className="code text-small">&lt;- This is your IP address</span>
                </li>
              )}
              <li>192.168.1.0/24</li>
              <li>192.168.1.0</li>
              <li>192.168.1.255</li>
              <li>192.168.1.0 255.255.255.0</li>
              <li>2500:2000:4123::</li>
              <li>2500:2000:4123::/64</li>
            </code></pre>
            <br />
            <p>
              Detailed information about ip subnetting is calculated, including
              CIDR notation, number of hosts, network address, network size, int value, host address and broadcast address.  A bit map
              displays the binary format of the IP address. If the subnet is referenced in an RFC, the subnet usage displays the RFC number.
            </p>
            <p>
              The tool displays a chart of the subnet's summary networks,
              network subnets, and adjacent subnets that have the same
              mask. Each subnet has one adjacent subnet that shares the same
              parent. That will be shown in a slightly larger box. Any box is
              clickable which will recenter the chart. The IPv4 summary blocks include
              the index number of the subnet within that summary.
            </p>
            <p>
              If you provide an IP address, for example 192.168.1.46/28, the tool will
              show the host bits as grey and the subnet bits as blue.
            </p>
            <p>
              The tool converts between long and abreviated IPv6
              notation (compressed IPv6), calculate the wildcard bits of IPv4 subnets as required
              in some ACLs, calculate the subnet size including IPv6, and
              provide the host position within an IPv4 subnet. Conversions
              between CIDR format and dot masks are also shown for IPv4.
            </p>
            <p>
              Classful subnet mask information can also be displayed by selecting the classful check box.
              The tool highlights the bits found before a zero bit. This determines the correct class.
              A class bar in green covers the CIDR mask bar that is displayed in grey. Note that classful
              networks are not widely used in production and are found largely in certification programs.
            </p>
            <h5>
              IPv6 Calculator with host bits
            </h5>
              The graphical display includes host bits for IPv6 subnets. But they are dropped as part of the subnet 
              calculation.  You can leave off the mask and the tool will calculate the best fit mask.  For example: 
              if you have 1000:2000:3000:4000:5000:6000::/32, and you would like to know what mask keeps all bits under the 
              network portion, you can enter 1000:2000:3000:4000:5000:6000:: and the tool will calculate that value to be /83.
            <h5>Hex to IP calculator</h5>
            <p>You can convert an 8 character hexidecimal number to an IPv4 address. The hex is not case sensitive.
              This only applies to IPv4 because IPv6 is already in hex format. The results assume a host address.
              Converting from an IPv4 address to a hexidecmial number is part of the normal calculator results.
            </p>
          </div>
        </Container>
      )}
      <SubSizeTable mask={subnet.element ? subnet.element.maskVal : 0} ipv6={subnet.element ? subnet.element.ipv6 : false} />
      <YouTube vid="xG3hFdGTiAw"/>
    </>
  );
};

export default SubnetFlow;
