import { React } from 'react';
import { useNavigate } from "react-router-dom";

let calcImg = process.env.REACT_APP_HOST + "/img/icons/icon61.svg";
let fourin6 = process.env.REACT_APP_HOST + "/img/icons/fourin6.svg";
let prefixImg = process.env.REACT_APP_HOST + "/img/icons/icon67.svg";
let allocateImg = process.env.REACT_APP_HOST + "/img/icons/pieslice.svg";
let patternImg = process.env.REACT_APP_HOST + "/img/icons/pattern.svg";
let vennImg = process.env.REACT_APP_HOST + "/img/icons/venn.svg";
let treeImg = process.env.REACT_APP_HOST + "/img/icons/subtree.svg";
let plannerImg = process.env.REACT_APP_HOST + "/img/icons/planner.svg";
let trophyImg = process.env.REACT_APP_HOST + "/img/icons/icon76.svg";
let routeImg = process.env.REACT_APP_HOST + "/img/icons/icon70.svg";
let practiceImg = process.env.REACT_APP_HOST + "/img/icons/icon94.svg";

export const Sidebar = (props) => {
    const navigate = useNavigate();

    const follow = (t) => (e) => {
        e.currentTarget.parentNode.childNodes.forEach(n=>n.classList.remove("active"));
        props.setTitle(t);
        e.currentTarget.classList.add("active");
        navigate(t);
    }
    let refTool = props.refTool;

    

    return (<>
        {window.visualViewport.width > 1000 ?
            <>
                <div className="sidefiller"></div>
                <div className="sidebar">
                    <div className={refTool==='Calc'?"reftool":"clickable"} role="button" tabindex="0" onClick={follow("/act/app/subnet.html")}><img src={calcImg} alt="Subnet Calculator" />
                    <div className="docs">Calc</div></div>
                    <div className="clickable" onClick={follow("/act/app/prefix.html")} role="button" tabindex="0"><img src={prefixImg} alt="Prefix Optimizer" />
                    <div className="docs">Prefix</div></div>
                    <div className={refTool==='Tree'?"reftool":"clickable"} role="button" onClick={follow("/act/app/tree.html")} tabindex="0"><img src={treeImg} alt="Subnet Tree tool" />
                    <div className="docs">Tree</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/diffs.html")} tabindex="0"><img src={vennImg} alt="Subet Diff Tool" />
                    <div className="docs">Diffs</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/routes.html")} tabindex="0"><img src={routeImg} alt="Route Anaylzer" />
                    <div className="docs">Routes</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/embed.html")} tabindex="0"><img src={fourin6} alt="NAT64 Translator" />
                    <div className="docs">NAT64</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/planner.html")} tabindex="0"><img src={plannerImg} alt="Subnet Planner" />
                    <div className="docs">Planner</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/allocate.html")} tabindex="0"><img src={allocateImg} alt="Alocate Subnets" />
                    <div className="docs">Allocate</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/pattern.html")} tabindex="0"><img src={patternImg} alt="Subnet Templates" />
                    <div className="docs">Patterns</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/practice.html")} tabindex="0"><img src={practiceImg} alt="Subnet Practice" />
                    <div className="docs">Practice</div></div>
                    <div className="clickable" role="button" onClick={follow("/act/app/challenge.html")} tabindex="0"><img src={trophyImg} alt="Subnet Challenge" />
                    <div className="docs">Game</div></div>
                </div>
            </> : null}
            </>
    );
}