import { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { TreeForm } from "../components/forms/tree_form";
import { useHeaderTags } from "../components/custom_hook";
import { SummaryFolder } from "../components/summary_folder";
import { SummaryGraph } from "../components/summary_graph";
import { SummaryTape } from "../components/summary_tape";
import { PrefixClasz } from "../components/prefix_class";

export const TreeFlow = (props) => {
  const [prefixes, setPrefixes] = useState();
  const [showHelp, setShowHelp] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [refresh, setRefresh] = useState(true);
  const [winWidth, setWinWidth] = useState(1000);
  const [parent, setParent] = useState();

  const codeRef = useRef();

  useHeaderTags(props);
  const fromNet = searchParams.get("from")
  const toNet = searchParams.get("to")

  const followSub = (e) => {
    let tar = e.currentTarget.textContent.replace("/", "_");
    window.location.href = "/act/app/subnet/" + tar;
  }

  const getFormat = (tag) => {
    switch (tag) {
      case 'f':
        return 'folder';
      case 'x':
        return 'text-contrast';
      default:
        return ''
    }
  }

  const updatePrefixes = (p) => {
    setParent(null);
    setPrefixes(p);
  }

  const setFocusNode = (node) => {
    if (node == null) {
      setParent(null);
      return;
    }
    let nets = prefixes.nodes.map(p => {
      return new PrefixClasz(p);
    });
    let result = [];
    if (nets[0].is4())
      nets.forEach(n => {
        result = n.findPath4(node, result);
      });
    else
      nets.forEach(n => {
        result = n.findPath6(node, result);
      });
    result.reverse();
    setParent(result);
  }

  useEffect(() => {
    if (refresh && codeRef.current) {
      setRefresh(false);
      let panel = codeRef.current.parentNode.parentNode.clientWidth - 58;
      let codeNode = codeRef.current.parentNode.getElementsByTagName("code")[0].getBoundingClientRect().width;
      setWinWidth(panel - codeNode);
    }
  }, [refresh]);


  return (
    <>
      <Container>
        <TreeForm
          setPrefixes={updatePrefixes}
          showHelp={setShowHelp}
          doRefresh={setRefresh}
          prePopulate={fromNet ? { fromNet, toNet } : undefined}
        ></TreeForm>
        {showHelp &&
          <div className="docs paper">
            <h3>Description</h3>
            The tree tool will analyze a list of subnets and organizes the prefixes into a binary tree. Its useful when you
            want to examine your address plan to identify gaps or overlaps.
            <li>Identify potential summary routes</li>
            <li>Discover the subnet hierarchy in a list of prefixes</li>
            <br />
            There are some other tasks this tool is useful for.  Some common examples:
            <li>find out if two addresses are on the same subnet</li>
            <li>find the summary route for subnets</li>
            <li>find overlapping subnets</li>
            <li>find summaries with missing subnets</li>
            <li>filter a list of subnets through a prefix-filter element</li>
            <br /><h3>Usage</h3>
            <p>Try out these examples by pasting and submitting them above.</p>
            <div className="tab">
              <pre>
                <code>
                  10.33.99.0/27<br />
                  192.168.7.0/24<br />
                  10.64.110.0/24<br />
                  172.16.32.0/22<br />
                  172.16.0.0/18<br />
                </code>
              </pre>
            </div>
            <p>The tool will create a summary blocks that organize subnets around bit boundaries.
              You can choose to include missing subnets to cover prefixes that aren't seen. This tool
              is useful when studying route tables or address plans.
            </p>
            <pre>
              <code>
                2000:1000:500:24c::/64<br />
                2000:1000:500:251::/64<br />
                2000:1000:500:250::/62<br />
                2000:1000:520:250::/64
              </code>
            </pre>
            <p>You can also determine what subnet is common with 2 or more IP address.</p>
            <pre>
              <code>
                192.168.1.1<br />
                192.168.1.14<br />
              </code>
            </pre>
            <h4>Range of IP Addresses</h4>
            You can find enter a range of IP address or Subnets by adding a hypen between the first and second address or subnet.
            For example: entering <code>10.20.30.40/20 - 10.21.22.23</code> will result in the output:
            <img style={{'width': '65%'}} src={process.env.REACT_APP_HOST+"/img/tree_ss_1.png"}></img>
            <br/>This tells us that 10.20.0.0/15 is the smallest subnet that covers both the /20 and the 10.21.22.23 IP address. The tool has determined that 10.21.22.16/29 is 
            the smallest subnet that could hold the IP address.  It has also ignored the hostbits that we included in the /20 leaving 10.20.16.0/20 as the 
            correct subnet.  The graph also represents the respective size and location of the two smaller subnets under the common /15.
            <br/><h5>Notes:</h5>
            <p>
              Auto generated common subnets folders are shown <span className="folder">outlined</span>.
            </p>
            <h4>Fixed Mask Size</h4>
            By default, the tool will auto generate folders to collect loose subnets. You can disable this feature and provide fixed folder size.  If you set 
            the fixed size to 0, then no folders will be created.  Natural overlaps in the input are still shown. Fixed folder sizes are usefull if you are interested in the 
            distribution of subnets over defined major blocks or summaries.
            <h4>Prefix Filter</h4>
            You may have a list of subnets where you are only interest in a subset. You can use the prefix filter to 
            isolate those from the larger list.  
          </div>}
      </Container>
      {!showHelp && (
        <Container>
          <Row>
            <Col id="subs">
              <div>
                <div>&nbsp;</div>
                <div className="text-dark">
                  Subnets and Folders
                </div>
                <pre className="half-panel inline">
                  <code ref={codeRef}>
                    {prefixes &&
                      prefixes.nodes.map((subnet, id) => {
                        return (
                          <SummaryFolder setRefresh={setRefresh} canvasRef={codeRef} key={id} folder={subnet} />
                        );
                      })}
                  </code>
                  <svg width={winWidth}>
                    {prefixes && codeRef.current &&
                      prefixes.nodes.map((subnet, id) => {
                        return (
                          <SummaryTape parents={{ parent: parent, setFocusNode: setFocusNode }} winWidth={winWidth - 25} refresh={refresh} key={id} folder={subnet} root={true} box={{ x: 0.0, w: 1.0 }} parentY={0} />
                        );
                      })}
                  </svg>
                </pre>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
