import { React } from 'react';
import { BlockedBits } from "./blocked_bits";

export const Bitmap = (props) => {
    let subnet = props.subnet;
    let net_str = "";
    if (props.subnet.ipv6) {
        let net_short = props.subnet.ipv6Long.split('/')[0];
        net_str = net_short.split(":").map(oct => {
            return ("0000"+oct).slice(-4);
        }).join("")
    } else {
        net_str = props.subnet.ipaddr.split("/")[0];
    }
    return(
        <div className="bitmap">
            {subnet && <BlockedBits classful={props.classful} len={props.width} rfc={props.rfc} offset={props.offset} values={subnet.values} ipv6={subnet.ipv6} mask={subnet.maskVal} conflict={props.conflict} net_str={net_str} clsmap={props.clsmap} allbits={subnet.allBits}></BlockedBits>}
        </div>
    )
}