import { React, Fragment } from 'react';

export const BlockedBits = (props) => {
    let group = props.ipv6 ? 16 : 8;
    let field = props.ipv6 ? 128 : 32;
    let center = props.ipv6 ? 0 : 4 * (128 - 32) / 2;
    let collidedAt = 128;
    let net_char = props.ipv6 ? [...props.net_str] : props.net_str.split('.');
    let v4mask1 = props.offset * 4;
    let v4maskLen1 = props.len * 4;
    let v4mask2 = 0;
    let v4maskLen2 = 0;
    if (props.rfc) {
        switch (Number(props.offset)) {
            case 40:
                v4mask2 = 72 * 4;
                v4maskLen2 = 32;
                v4maskLen1 = 24 * 4;
                break;
            case 48:
                v4mask2 = 72 * 4;
                v4maskLen2 = 16 * 4;
                v4maskLen1 = 16 * 4;
                break;
            case 56:
                v4mask2 = 72 * 4;
                v4maskLen2 = 24 * 4;
                v4maskLen1 = 32;
                break;
            case 64:
                v4mask1 = 72 * 4;
                break;
            default:
                v4mask1 = 72 * 4;

        }
    }
    let bits = [...Array(field)].map((v, i) => {
        let sigDigit = Math.floor(i / 32);
        let word = BigInt(props.values[sigDigit]);
        let collision = false;
        let ptr = 1n << BigInt(i - (sigDigit * 32));
        let set = (ptr & word) > 0;
        if (props.allbits.length > 0) {
            set = props.allbits.charAt(props.allbits.length -1 -i) == '1';
        }
        let hostbit = i < (field - props.mask);
        if (props.conflict) {
            let conflict = BigInt(props.conflict[sigDigit]);
            collision = (ptr & conflict) > 0;
            if (collision > 0 && collidedAt === 128) {
                collidedAt = 128 - i;
            }
        }
        let shift = (i & group) > 0 ? 2 : 1;
        return {
            "index": i,
            "set": set,
            "ranged": i >= props.offest & i < props.offset + props.len,
            "shift": shift,
            "collision": collision,
            "hostbit": hostbit
        }
    });
    let classMask = null;
    let classfullName = null;
    if (props.clsmap) {
        let classInfo = props.clsmap.split(":");
        classMask = Number(classInfo[1]);
        classfullName = classInfo[0];
    }

    let w = props.len?props.len:window.innerWidth;
    let vbox = `0 0 512 26`;
    if (!props.ipv6 && w < 1000) {
        vbox = `178 0 183 26`;
    }
    const maskBox = () => {
        let cidr = <rect x={center} y={0} width={props.mask * 4} height="2" fill="rgb(64 140 242)"></rect>
        let cmask = <rect x={center} y={0} width={classMask * 4} height="2" fill="rgb(27, 27, 221)"></rect>
        if (!props.classful) {
            return <>{cidr}</>;
        }
        return props.mask < classMask ?<>{cmask}{cidr}</>:<>{cidr}{cmask}</>
    }
    return (
        <>
            <svg viewBox={vbox} className="maxsvg">
                {props.offset &&
                    <><rect x={v4mask1} y={24} width={v4maskLen1} height="2" fill="rgb(126 126 164)"></rect>
                        {v4mask2 > 0 && <rect x={v4mask2} y={24} width={v4maskLen2} height="2" fill="rgb(50 52 224)"></rect>}
                    </>}
                {bits.reverse().map((bit, i) => {
                    return (<Fragment key={i}>
                        {props.ipv6 ? <text x={(i * 16) + 6} y={22} className="bitText">{net_char[i]}</text> :
                            <text y={24} x={(i * 32) + 12 + center} className="bitText">{net_char[i]}</text>}
                        {bit.hostbit ?
                            <rect x={(i * 4) + center} y={4} width={4} height={8 + (bit.shift * 2)} fill={bit.set ? 'rgb(124 162 226)' : '#c8c8c8'} stroke="#707070" strokeWidth="0.5px"></rect> :
                            <rect x={(i * 4) + center} y={4} width={4} height={8 + (bit.shift * 2)} fill={bit.set ? 'rgb(24 224 146)' : '#f4f4f4'} stroke="#585858" strokeWidth="0.5px"></rect>}
                        {i % 4 === 3 && <ellipse cx={(i * 4) + center + 4} cy={3} rx={.5} ry={.5} fill="darkgreen"></ellipse>}
                        {bit.collision && <rect x={(i * 4) + center} y={4} width={4} height={6} fill="orange"></rect>}
                    </Fragment>
                    )
                })}
                {props.ipv6 &&
                <line x1={64*4} x2={64*4} stroke="black" width="3" y1="16" y2="30"></line>}
                {props.classful && <>
                <rect x={center} y={2} width={classMask/2} height={16} stroke="teal" fill="rgb(128 0 0 255)" fillOpacity="0%" strokeWidth=".6px"></rect> 
                <text x={center - 12} y={12} className="classNameText">{classfullName}</text>
                </>}
                {maskBox()}
            </svg>
            {collidedAt !== 128 && <div className="docs">Bit conflict starting at {collidedAt}</div>}
        </>
    )
} 